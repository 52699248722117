/**
 * create by wangchunyan1 on 2019/8/7
 */
import React from 'react';

import picPcTeacher from '../images/downloadMobile/pic_pcTeacher.png';
import picPcStudent from '../images/downloadMobile/pic_pcStudent.png';
import picMobileTeacher from '../images/downloadMobile/pic_mobileTeacher.png';
import picMobileStudent from '../images/downloadMobile/pic_mobileStudent.png';
import picTips from '../images/downloadMobile/pic_tips.png'

import './downloady.less';

import LayoutYd from '../components/layoutYd';
import Message from '../components/message/message';

import Clipboard from 'react-clipboard.js';


import {get} from '../utils/apis';

import config from '../../config/config';
import { isInWindow } from '../utils/tools';


class DownloadY extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      studentApp: {},
      teacherApp: {},
      androidApp: {},
      androidTeacherApp: {},
      screenPlay: {},
      messageInfo: '',
      isAndroid: this.isAndroid(),
      showTips: false
    };
  }

  componentWillMount() {
    this.init();
  }

  init() {
    this.getStudentDownloadInfo().catch(() => {
    });
    this.getTeacherDownloadInfo().catch(() => {
    });
    this.getScreenPlayDownloadInfo().catch(() => {
    });
    this.getAppDownloadInfo().catch(() => {
    });
    this.getTeacherAppDownloadInfo().catch(() => {
    });
  }

  // 判断设备为 android
  isAndroid() {
    if (isInWindow()) {
      const u = window.navigator.userAgent.toLowerCase();
      return u.indexOf('android') > -1 || u.indexOf('adr') > -1;
    }
  }

  isWx() {
    if (isInWindow()) {
      const ua = window.navigator.userAgent.toLowerCase();
      return ua.includes('micromessenger');
    }
  }

  async getStudentDownloadInfo() {
    const result = await get(`${config.url.hook}/software?t=student`);
    if (result.code === 0) {
      this.setState({
        studentApp: result.data,
      });
    }
  }

  async getTeacherDownloadInfo() {
    const result = await get(`${config.url.hook}/software?t=teacher`);
    if (result.code === 0) {
      this.setState({
        teacherApp: result.data,
      });
    }
  }

  async getScreenPlayDownloadInfo() {
    const result = await get(`${config.url.hook}/software?t=screenPlay`);
    if (result.code === 0) {
      this.setState({
        screenPlay: result.data,
      });
    }
  }

  async getAppDownloadInfo() {
    const result = await get(`${config.url.hook}/downloadApp?type=student`);
    if (result.code === 0) {
      this.setState({
        androidApp: result.data,
      });
    }
  }

  async getTeacherAppDownloadInfo() {
    const result = await get(`${config.url.hook}/downloadApp?type=teacher`);
    if (result.code === 0) {
      this.setState({
        androidTeacherApp: result.data,
      });
    }
  }

  onCopyError() {
    this.setState({
      messageInfo: '复制失败，建议换个浏览器试试~',
    });
    this.hideMessage();
  }

  onCopySuccess(msg) {
    if (typeof msg !== 'string') {
      msg = '复制成功，请去电脑端打开链接下载~'
    }
    this.setState({
      messageInfo: msg,
    });
    this.hideMessage();
  }

  onDownLoadApp(url) {
    if (this.isWx()) {
      this.setState({
        showTips: true
      })
    } else {
      if (isInWindow()) {
        window.location.href = url;
      }
    }
  }

  onHideTips = () => {
    this.setState({
      showTips: false
    })
  };

  hideMessage() {
    setTimeout(() => {
      this.setState({
        messageInfo: '',
      });
    }, 3000);
  }


  render() {
    return (
      <LayoutYd>
        <div className='downloadWrap'>
          <div className='downloadBox'>
            <div className='icon'>
              <img src={picPcTeacher} alt='PC 教师端' />
            </div>
            <h3 className='title'>PC 教师端</h3>
            <h4 className='subTitle'>学习变简单，教育更公平</h4>
            <p>{`适用于：${this.state.teacherApp.OS || ''}`}</p>
            <p>{`版本：${this.state.teacherApp.MainVersion || ''}`}</p>
            <Clipboard className='copyAddress' data-clipboard-text={this.state.teacherApp.InstallAddr || '链接丢失了'}
                       onError={this.onCopyError.bind(this)}
                       onSuccess={this.onCopySuccess.bind(this)}>复制下载链接</Clipboard>
          </div>
          <div className='downloadBox'>
            <div className='icon'>
              <img src={picPcStudent} alt='PC 学生端' />
            </div>
            <h3 className='title'>PC 学生端</h3>
            <h4 className='subTitle'>学习变简单，教育更公平</h4>
            <p>{`适用于：${this.state.studentApp.OS || ''}`}</p>
            <p>{`版本：${this.state.studentApp.MainVersion || ''}`}</p>
            <Clipboard className='copyAddress' data-clipboard-text={this.state.studentApp.InstallAddr || '链接丢失了'}
                       onError={this.onCopyError.bind(this)}
                       onSuccess={this.onCopySuccess.bind(this)}>复制下载链接</Clipboard>
          </div>
          <div className='downloadBox'>
            <div className='icon'>
              <img src={picMobileTeacher} alt='PAD 教师端' />
            </div>
            <h3 className='title'>PAD 教师端</h3>
            <h4 className='subTitle'>学习变简单，教育更公平</h4>
            <p>{`版本：${this.state.androidTeacherApp.versionName || ''}`}</p>
            {
              this.state.isAndroid ? (
                <button className='copyAddress' onClick={this.onDownLoadApp.bind(this, this.state.androidTeacherApp.apkUrl)}>点击下载</button>
              ) : (
                <Clipboard className='copyAddress' data-clipboard-text={this.state.androidTeacherApp.apkUrl || '链接丢失了'}
                           onError={this.onCopyError.bind(this)}
                           onSuccess={this.onCopySuccess.bind(this, '复制成功，请用安卓PAD打开链接下载~')}>复制下载链接</Clipboard>
              )
            }
            <h4 className='subTitle' style={{ marginTop: '24px' }}>投屏插件</h4>
            <p>用于PAD上课，并且需要在教室内投屏，请下载投屏插件</p>
            <Clipboard className='copyAddress screenPlay' data-clipboard-text={this.state.screenPlay.InstallAddr || '链接丢失了'}
                       onError={this.onCopyError.bind(this)}
                       onSuccess={this.onCopySuccess.bind(this)}>复制下载链接</Clipboard>
          </div>
          <div className='downloadBox'>
            <div className='icon'>
              <img src={picMobileStudent} alt='安卓学生端' />
            </div>
            <h3 className='title'>安卓手机学生端</h3>
            <h4 className='subTitle'>学习变简单，教育更公平</h4>
            
            <p>{`版本：${this.state.androidApp.versionName || ''}`}</p>
            {
              this.state.isAndroid ? (
                <button className='copyAddress' onClick={this.onDownLoadApp.bind(this, 'https://et-jery.oss-cn-beijing.aliyuncs.com/js/app-release.apk')}>点击下载</button>
              ) : (
                <Clipboard className='copyAddress' data-clipboard-text={'https://et-jery.oss-cn-beijing.aliyuncs.com/js/app-release.apk'
 || '链接丢失了'}
                           onError={this.onCopyError.bind(this)}
                           onSuccess={this.onCopySuccess.bind(this, '复制成功，请用安卓手机打开链接下载~')}>复制下载链接</Clipboard>
              )
            }
          </div>
        </div>
        {this.state.messageInfo !== '' && <Message info={this.state.messageInfo} />}
        {this.state.showTips && (
          <div className='picTipsWrap' onClick={this.onHideTips}>
            <img className='picTips' src={picTips} />
          </div>
        )}
      </LayoutYd>
    );
  }
}

export default DownloadY;
